<!--
 * @Author: 陈剑伟
 * @Date: 2020-04-21 16:44:17
 * @LastEditTime: 2020-04-27 10:18:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\activiti\ModelList\index.vue
 -->
<template>
  <div class="model-wrapper">
    <div class="search-container">
       <el-form ref="form" :model="form" label-width="100px">
          <el-row>
            <el-col :span="6">
                <el-form-item label="流程定义key">
                  <el-input clearable v-model="form.key"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="18" class="text-right">
                <el-button type="primary" @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
                <el-button type="primary" @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
            </el-col>
          </el-row>
       </el-form>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
         <el-table-column
            type="index"
            align="center"
            label="序号"
            width="55">
          </el-table-column>
				<el-table-column
					type="selection"
          align="center"
					width="55">
				</el-table-column>
        <el-table-column
					prop="procInstId"
          align="center"
					label="流程实例ID"
					min-width="300">
				</el-table-column>
				<el-table-column
					prop="name"
          align="center"
					label="流程名称"
					min-width="160">
				</el-table-column>
				<el-table-column
					prop="version"
          align="center"
					label="版本"
					width="100">
				</el-table-column>
				<el-table-column
					prop="applyer"
          align="center"
					label="申请人"
					min-width="150">
				</el-table-column>
				<el-table-column
					prop="key"
          align="center"
					label="标识key"
					width="150">
				</el-table-column>
				<el-table-column
					prop="currTaskName"
          align="center"
					label="当前环节"
					min-width="150">
				</el-table-column>
        <el-table-column
					prop="deployId"
          align="center"
					label="部署ID"
					min-width="266">
				</el-table-column>
        <el-table-column
					prop="isSuspended"
          align="center"
					label="状态"
					min-width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.isSuspended">挂起</span>
            <span v-else>激活</span>
          </template>
				</el-table-column>
        <el-table-column
          align="center"
          width="300px"
					:label="$t('option')">
          <template slot-scope="scope">
            <el-button v-if="buttonAuth.includes('act:procInstRuns:update')" class="default-button" @click="openDeleteDialog(scope.row, 10)">{{ scope.row.isSuspended?'激活':'挂起' }}</el-button>
            <!-- <el-button class="default-button" @click="changeNodeDialog(scope.row)">节点配置</el-button> -->
            <el-button v-if="buttonAuth.includes('act:procInstRuns:remove')" class="default-button" @click="openDeleteDialog(scope.row, 1)">删除</el-button>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="page-wrapper">
      <div class="">
        <el-button v-if="buttonAuth.includes('act:procInstRuns:remove')" class="default-button"  @click="handleDelete(selectedArr.map((item) => item.id).join(','))">{{$t('delete')}}</el-button>
      </div>
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- 图片预览 -->
    <preview-image :src="previewImage.src" :show="previewImage.visible" @closeDialog="() => {this.previewImage.visible=false}"></preview-image>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ModelSearchClass from "./procInstRunsClass.js";
import apis from '@/apis'
import mixin from '@/mixins/index'
import download from '@/mixins/download'
import confirmDialog from '@/components/confirmDialog.vue'
import previewImage from "@/components/previewImage.vue";

const modelerBaseUrl = process.env.VUE_APP_MODEL_BASE_URL
export default {
  data() {
    return {
      form: new ModelSearchClass('form'),
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 10：挂起
        data: {},
      },
      tableData: [],
      modelerUrl: "",
      modelerLoading: "",
      addDialog: {
        visible: false,
        isEdit: false,
      },
      previewImage: {
        visible: false,
        src: "",
      },
      modelList: {
        visible: false,
      },
    }
  },
  mixins: [mixin, download],
  //部件
  components: {
    confirmDialog,
    previewImage,
  },
  //静态
  props: {},
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {
    ...mapState(['Language']),
  },
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {
    /**
     * data: 节点配置
     */
    changeNodeDialog(data) {
      this.$router.push({
        path: "procDefNode",
        query: {
          procDefId: data.id,
        },
      })
    },
    uploadSuccess(res) {
      this.$message.success("上传成功")
    console.log("uploadSuccess -> res", res)
    },
    uploadError(res) {
    console.log("uploadError -> res", res)
    },
    onPreviewImages(record) {
      this.$http.get(`${apis.process}/show?ext=.png&did=${record.deploymentId}`, {
        responseType: 'blob',
      }).then((res) => {
         const raw = res.data
        this.previewImage.src = URL.createObjectURL(raw)
        this.previewImage.visible = true
      }, (e) => {
        console.log(e)
      })
    },
    handleAdd() {
      this.modelList.visible = true
      // this.modelerUrl = modelerBaseUrl + '/models/newModel?time=' + new Date().getTime()
      this.modelerUrl = `${modelerBaseUrl}/models/newModel?time=${new Date().getTime()}`
    },
    /**
     * 新增弹窗
     */
    handleClose() {
      this.modelList.visible = false
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent()
      }
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 1) {
        this.modelDelete(this.confirmDialog.data.id)
      } else {
        this.modelHangOn()
      }
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 10:挂起
     */
    openDeleteDialog(data, type) {
      this.confirmDialog.type = type
      this.confirmDialog.data = data
      this.changeConfirmDialog(true, type)
    },
    handleDelete(ids) {
      this.confirmDialog.data.id = ids;
      this.changeConfirmDialog(true, 1)
    },
    /**
     * 删除
     */
    modelDelete(ids) {
      this.$http.post(`${apis.processInsRemove}?ids=${ids}&reason=流程终止`).then((res) => {
      if (res.data.code === 0) {
          this.$message.success("删除成功！")
          this.$refs.confirmDialog.closeDialog()
          this.searchData()
        }
      })
    },
    /**
     * 挂起-激活
     */
    modelHangOn() {
      const state = this.confirmDialog.data.isSuspended ? 'active' : 'suspend'
      this.$http.post(`${apis.processInss}/update/${this.confirmDialog.data.id}/${state}`).then((res) => {
      if (res.data.code === 0) {
        this.$message.success("修改成功！")
          this.$refs.confirmDialog.closeDialog()
          this.searchData()
        }
      })
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.get(apis.processIns, {
        params: this.form,
      }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new ModelSearchClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
  },
  //请求数据
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.xml-style {
  color: @defaultButton-background;
  cursor: pointer;
}
.search-container {
  margin-top: 20px;
}
.page-wrapper {
  margin-top: 20px;
}
.file-upload {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.text-right {
  text-align: right;
}
</style>
