/*
 * @Author: genwai
 * @Date: 2020-04-21 16:59:32
 * @LastEditTime: 2020-04-23 16:46:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\activiti\ModelList\modelClass.js
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.key = ''
    }
  }
}

export default searchFrom
